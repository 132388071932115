<template>
  <BaseFinancialStatement docType="CASH_FLOW" />
</template>

<script>
import BaseFinancialStatement from '@/component/BaseFinancialStatement.vue'

export default {
  name: 'CashFlowList',
  components: { BaseFinancialStatement },
}
</script>
